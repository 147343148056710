import { ExtraTypographyVariant, Theme, createTheme } from "@mui/material"
import { ColorPartial } from "@mui/material/styles/createPalette"
import { Typography, TypographyOptions } from "@mui/material/styles/createTypography"
import * as color from "./colors"

type GreyColors = ColorPartial & {
  dark: string
  light: string
  ultraLight: string
  extraLight: string
}

const setMuiColor = (yannColor: color.YannColor) => ({
  ...yannColor,
  light: yannColor.light1,
  main: yannColor.main,
  dark: yannColor.dark1,
  contrastText: yannColor.contrastText,
})

const customPalette = {
  primary: setMuiColor(color.primary),
  secondary: setMuiColor(color.secondary),
  tertiary: setMuiColor(color.tertiary),
  purple: setMuiColor(color.purple),
  loyalty: setMuiColor(color.purple),
  red: setMuiColor(color.red),
  negativeSegmentation: setMuiColor(color.red),
  header: {
    light: color.primary.light1,
    main: color.primary.main,
    dark: color.primary.dark1,
    gradient: "",
    containedButton: {
      backgroundColor: color.primary.main,
      primaryColor: "white",
      secondaryColor: "white",
      hover: {
        backgroundColor: color.primary.dark2,
      },
    },
  },
  text: {
    primary: color.other.darkBlue,
    secondary: color.grey.dark,
  },
  grey: {
    dark: color.grey.dark,
    light: color.grey.light,
    ultraLight: color.grey.disabled,
    extraLight: color.grey.extraLight,
  } as GreyColors,
  success: {
    main: color.secondary.main,
  },
  common: {
    ...color.other,
  },
}
export type CustomPalette = typeof customPalette
export type CustomColorPaletteNames = keyof Pick<
  CustomPalette,
  | "negativeSegmentation"
  | "loyalty"
  | "primary"
  | "secondary"
  | "tertiary"
  | "purple"
  | "success"
  | "red"
>

const rootTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1024,
      md: 1280,
      lg: 1536,
      xl: 1920,
    },
  },
  palette: customPalette,
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    htmlFontSize: 17, // = 1rem
  },
})

const customTypography = ({ palette, typography }: Theme): TypographyOptions => ({
  h1: {
    fontWeight: 700,
    fontSize: "1.5rem",
    color: palette.grey.dark,
  },
  button: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    lineHeight: "15px",
  },
  // Font sizes shorthands to apply Abstract px sizes in rem
  ...createTypographyVariants(
    typography,
    [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 27, 29, 30],
  ),
})

const createTypographyVariants = (typography: Typography, pxSizes: number[]) =>
  pxSizes.reduce<TypographyOptions>((result, pxSize) => {
    result[`${pxSize}px` as ExtraTypographyVariant] = {
      fontFamily: "inherit",
      fontSize: typography.pxToRem(pxSize),
      lineHeight: typography.pxToRem(pxSize + 2),
    }
    return result
  }, {})

/**
 * Base theme: exports palette colors, typography or spacing function
 * for theme definitions
 */
export const baseTheme = createTheme(rootTheme, {
  typography: customTypography(rootTheme),
})
