import { ColumnDefinition, Table, TableProps } from "@pharmupp/p3-front-commons"
import { AdherentDefaultFilters } from "../defaultFilters"
import { ApiAdherentLoyaltyList } from "./types"

export const AdherentsLoyaltyList = ({
  queryConfig,
  exportButtonProps,
  columns,
  extraToolbarElements,
}: { columns: ColumnDefinition<ApiAdherentLoyaltyList>[] } & Pick<
  TableProps<ApiAdherentLoyaltyList, ApiAdherentLoyaltyList>,
  "queryConfig" | "exportButtonProps" | "extraToolbarElements"
>) => {
  return (
    <Table<ApiAdherentLoyaltyList>
      queryConfig={queryConfig}
      exportButtonProps={exportButtonProps}
      extraToolbarElements={extraToolbarElements}
      FilterSectionElement={<AdherentDefaultFilters />}
      emptyMessage="Pas encore de pharmacie"
      columns={columns}
    />
  )
}
