import {
  BackLink,
  ERROR_KINDS,
  FormStatusSelector,
  SideNav,
  SideNavLink,
  TabbedForm,
  useHistory,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { CheckCircle, Send, Slash, Trash } from "react-feather"
import { DefaultValues } from "react-hook-form"
import { useParams } from "react-router-dom"
import { formToServer } from "../UpdateUser/userForm.adapter"
import { apiErrorHandlerConfig, isHandledError } from "../apiErrorHandlerConfig"
import { apiRoleConf } from "../apiRoleConf"
import { useMail } from "../helpers"
import { UnhandledError, useApiErrorHandler } from "../useApiErrorHandler"
import { ApiUserModel } from "./models/apiUser.model"
import { generalInfosTab } from "./tabs"
import { useUserFormApi } from "./useUserFormApi"

export type FormValues = ApiUserModel & {
  isUpdate?: boolean
  isConnectedUser?: boolean
  isExternSpecificationVisible?: boolean
  isReadOnly?: boolean
  isPrimary?: boolean
}

export interface UserFormProps {
  defaultValues: DefaultValues<FormValues>
  loading?: boolean
}

export function UserForm({ defaultValues, loading = false }: UserFormProps) {
  const mail = useMail()

  // ROUTER
  const { id } = useParams()
  const history = useHistory()
  const goBack = () => history.goBack("../")

  const isUpdate = !!id

  // API
  const apiConf = useRoleConf(apiRoleConf)
  const resourceEndpoint = apiConf.resourceEndpoint
  const { referential, referentialLoading, sendValues, ...api } = useUserFormApi()
  const apiErrorHandler = useApiErrorHandler<FormValues>(apiErrorHandlerConfig)

  // FORM
  const handleActivateError = (error: unknown) => {
    if (isHandledError(error)) {
      const saveError = apiErrorHandler.getSaveError(error)
      apiErrorHandler.displayError(saveError, () => {
        // nothing
      })
    }
  }

  const clickResendConfirmationHandler = (targetId: string) =>
    mail.resendConfirmation(targetId)

  return (
    <>
      {/* BACKLINK */}
      <BackLink to="../">Retour à la liste</BackLink>

      {/* SIDEBAR */}
      <SideNav>
        <SideNavLink label="Informations générales" to="./" />
      </SideNav>

      {/* FORM */}
      <TabbedForm<FormValues>
        tabs={[generalInfosTab]}
        title={
          defaultValues.isReadOnly
            ? "Visualiser un utilisateur"
            : isUpdate
              ? "Modifier un utilisateur"
              : "Créer un utilisateur"
        }
        initialValues={defaultValues}
        loading={referentialLoading || loading}
        onCancel={defaultValues.isReadOnly ? undefined : goBack}
        onSubmit={async (formValues, formMethods) => {
          if (defaultValues.isReadOnly) return

          const body = JSON.stringify(formToServer(formValues))
          await sendValues(body, {
            onSuccess(data) {
              formMethods.reset(data)
              if (!isUpdate) {
                goBack()
              }
            },
            onError(error) {
              const saveError = apiErrorHandler.getSaveError(
                error as unknown as UnhandledError,
              )
              if (
                saveError.type === "dialogAction" &&
                saveError.key === ERROR_KINDS.ALREADY_EXIST
              ) {
                saveError.props = {
                  ...saveError.props,
                  // @ts-expect-error complex type, but working
                  endpoint: `${resourceEndpoint}?force=true`,
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body,
                  onSuccess: goBack,
                }
              }

              apiErrorHandler.displayError(saveError, formMethods.setError)
            },
          })
        }}
        RouteElementProps={{ referential }}
        headerActions={[
          {
            label: "Renvoyer lien de connexion",
            icon: Send,
            onClick: () =>
              defaultValues.id && clickResendConfirmationHandler(defaultValues.id),
            hidden:
              !isUpdate ||
              !defaultValues.active ||
              defaultValues.isConnectedUser ||
              defaultValues.isReadOnly,
          },
          {
            label: defaultValues.active ? "Désactiver" : "Activer",
            icon: defaultValues.active ? Slash : CheckCircle,
            onClick: defaultValues.active
              ? () => api.deactivate(handleActivateError)
              : () => api.activate(handleActivateError),
            hidden: !isUpdate,
          },
          {
            label: "Supprimer",
            icon: Trash,
            onClick: () => api.deleteAction(goBack),
            hidden: !isUpdate || defaultValues.isPrimary || defaultValues.isReadOnly,
          },
        ]}
        tabsRightChildren={
          !!isUpdate && (
            <FormStatusSelector
              name="status"
              options={referential?.statuses || []}
              disabled
            />
          )
        }
      />
    </>
  )
}
