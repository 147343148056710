import {
  Box,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { PropsWithChildren } from "react"
import { ArrowRight } from "react-feather"

export const NegativeModal = () => {
  const { palette } = useTheme()
  const blueColor = palette.primary.light1
  return (
    <Stack gap={2}>
      <SectionText>
        La segmentation négative signifie que votre pharmacie n'a pas respecté au
        moins un des engagements fondamentaux requis par votre adhésion à Pharm-UPP.
        Ces engagements sont :
      </SectionText>

      <Card variant="outlined" sx={{ borderColor: blueColor, mb: 6 }}>
        <List>
          <CustomListItem color={blueColor}>
            L'exclusivité avec Pharm-UPP pour les laboratoires référencés
          </CustomListItem>
          <CustomListItem color={blueColor}>
            L'utilisation active d'Ospharm
          </CustomListItem>
          <CustomListItem color={blueColor}>
            Un pourcentage de parts de marché de 90% chez les quatre partenaires
            génériques référencés
          </CustomListItem>
        </List>
      </Card>

      <Box>
        <SectionTitle>
          Quelles sont les conséquences immédiates pour ma pharmacie ?
        </SectionTitle>
        <SectionText>
          Bien que les points Challenges et les valeurs des primes Vie des UPP
          continuent d'être comptabilisés, leur paiement est suspendu. Cette mesure
          est conforme aux termes de la convention d'adhésion que vous avez signée.
        </SectionText>
      </Box>

      <Box>
        <SectionTitle>Comment puis-je régulariser ma situation ?</SectionTitle>
        <SectionText>
          Pour remédier à cette situation, il est recommandé de contacter votre
          Conseiller Développement Officine (CDO). Votre CDO vous aidera à naviguer
          dans le processus Ospharm, vous informera sur tous les avantages de cette
          solution, vous conseillera sur la meilleure façon de collaborer avec les
          partenaires génériques référencés, et vous guidera sur les démarches à
          suivre pour confirmer votre exclusivité avec Pharm-UPP auprès de certains
          laboratoires.
        </SectionText>
      </Box>

      <Box>
        <SectionTitle>
          Mes points Challenges et primes Vie des UPP seront-ils versés après un
          retour à la normale ?
        </SectionTitle>
        <SectionText>
          Oui, absolument. Une fois que vous aurez régularisé votre situation et
          respecté les engagements, les paiements seront réactivés. L'objectif
          principal est d'aligner les moyens de financement des redistributions avec
          les redistributions elles-mêmes, pour assurer une gestion équitable et
          efficace.
        </SectionText>
      </Box>
    </Stack>
  )
}

const SectionTitle = styled(Typography)({ fontWeight: 600 })
const SectionText = styled(Typography)({ textAlign: "justify" })

const CustomListItem = ({
  color,
  children,
}: PropsWithChildren<{ color: string }>) => (
  <ListItem>
    <ListItemIcon>
      <ArrowRight color={color} />
    </ListItemIcon>
    <ListItemText>{children}</ListItemText>
  </ListItem>
)
