import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "@mui/material"
import {
  FieldsColumn,
  FieldsRow,
  FormNumberField,
  FormSearchField,
  Modal,
  ModalBasicActions,
  ModalContent,
  ModalTitle,
  Yup,
  YupTypes,
  api,
  useMutation,
  useQuery,
  useToaster,
} from "@pharmupp/p3-front-commons"
import {
  SearchOrCreateUserField,
  getRequiredUserSchema,
} from "@pharmupp/p3-user-domain"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { UserServiceParams } from "../../core/user-service.params"

interface CreateAdherentModalProps {
  open: boolean
  onClose: () => void
}

export function CreateAdherentModal({ open, onClose }: CreateAdherentModalProps) {
  const toaster = useToaster()
  const navigate = useNavigate()

  // REFERENTIAL
  const { data: referential, isLoading: loadingReferential } = useQuery({
    queryKey: ["adherent", "referential", "create"],
    queryFn: () =>
      api.get<{
        upps: { id: number; name: string }[]
      }>("/api/adherents/referential/create"),
  })

  const { mutateAsync: save } = useMutation({
    mutationFn: (formValues: CreateAdhFormValues) =>
      api.post<{ id: number }>("/api/adherents", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(formValues),
      }),
  })

  const formMethods = useForm<CreateAdhFormValues>({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  })

  const { reset, clearErrors } = formMethods

  const validateAction = formMethods.handleSubmit(
    async (formValues) =>
      save(formValues, {
        onSuccess(data) {
          toaster.success("Sauvegarde effectuée")
          navigate(`${data?.id}`)
        },
        onError(error) {
          console.error(error)
          onClose()
          reset()
          clearErrors()
        },
      }),
    (errors) => {
      console.error(errors)
      toaster.error("Veuillez remplir correctement le formulaire")
    },
  )

  return (
    <Modal loading={loadingReferential} open={open} onClose={onClose}>
      <ModalTitle>Nouvelle pharmacie</ModalTitle>

      <ModalContent>
        <Box py={1}>
          <FormProvider {...formMethods}>
            <FieldsColumn>
              <SearchOrCreateUserField<CreateAdhFormValues>
                name="primaryHolder"
                label="Titulaire principal"
                userServiceParams={UserServiceParams}
                required
              />
              <FieldsRow>
                <FormNumberField<CreateAdhFormValues>
                  name="cipCode"
                  label="Code CIP"
                  format="# ### ###"
                  fullWidth
                  required
                />
                <FormNumberField<CreateAdhFormValues>
                  name="cerpCode"
                  label="Code CERP"
                  format="#####"
                  fullWidth
                  required
                />
              </FieldsRow>
              <FormSearchField<number, CreateAdhFormValues>
                name="uppId"
                label="UPP de rattachement"
                options={(referential?.upps || []).map(({ id, name }) => ({
                  label: name,
                  code: id,
                }))}
                valueFormat="optionValue"
                required
              />
            </FieldsColumn>
          </FormProvider>
        </Box>
      </ModalContent>

      {/* ACTIONS */}
      <ModalBasicActions
        cancelAction={() => {
          clearErrors()
          onClose()
          reset()
        }}
        validateText="Créer la pharmacie"
        validateAction={validateAction}
      />
    </Modal>
  )
}

const schema = Yup.object({
  primaryHolder: getRequiredUserSchema("Titulaire requis"),
  cipCode: Yup.number().required().min(2000000).max(2999999),
  cerpCode: Yup.string()
    .required()
    // Yup.string().length(5) does not work on numbers
    .matches(/^\d{5}$/, "Le champ doit comporter 5 chiffres"),
  uppId: Yup.number().required(),
}).required()

type CreateAdhFormValues = YupTypes.InferType<typeof schema>
