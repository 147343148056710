import { Divider, Stack, StackProps, Typography, styled } from "@mui/material"
import { UserPlus } from "react-feather"

const UnstyledCreateUserButton = (props: StackProps) => (
  <Stack direction="column">
    <Divider />
    <Stack direction="row" spacing={2} {...props}>
      <UserPlus />
      <Typography>Créer un nouvel utilisateur</Typography>
    </Stack>
  </Stack>
)

export const CreateUserButton = styled(UnstyledCreateUserButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "0.875rem",
  margin: 0,
  padding: "0.5rem 1rem !important",
  paddingBottom: "0 !important",
  "& > p": {
    fontWeight: 600,
  },
  "&:hover": {
    color: theme.palette.primary.light,
    cursor: "pointer",
  },
  "&:active, &:focus": {
    color: theme.palette.primary.dark,
  },
}))
