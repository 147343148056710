import { ReferentialCode } from "@pharmupp/p3-front-commons"
import { formatISO, subMonths } from "date-fns"

const subMonthsAndGetIso = (months: number) =>
  formatISO(subMonths(new Date(), months), { representation: "date" })

export const MinEntryDates = {
  "3_MONTHS": { label: "Moins de 3 mois", code: subMonthsAndGetIso(3) },
  "6_MONTHS": { label: "6 mois", code: subMonthsAndGetIso(6) },
  "12_MONTHS": { label: "12 mois", code: subMonthsAndGetIso(12) },
} satisfies Record<string, ReferentialCode<string>>

export const minEntryDateOptions: ReferentialCode<string>[] = [
  MinEntryDates["3_MONTHS"],
  MinEntryDates["6_MONTHS"],
  MinEntryDates["12_MONTHS"],
]

export const statusOptions: Array<ReferentialCode<string> & { color: string }> = [
  {
    code: "ACTIVE",
    label: "Actif",
    color: "success.main",
  },
  {
    code: "DEACTIVATED",
    label: "Désactivé",
    color: "common.red",
  },
  {
    code: "IN_PROGRESS",
    label: "En cours",
    color: "#b7ad1d",
  },
]
