import { SummaryModel } from "../../types"
import { useAdherentOrdersReferential } from "../../useAdherentOrdersReferential"
import { OrderTable } from "../commons"
import { columns } from "../commons/columns"

type PastOrdersProps = {
  summary: SummaryModel
} & ReturnType<typeof useAdherentOrdersReferential> & {
    /** YYYY-MM-DD */
    startDate: string
    /** YYYY-MM-DD */
    endDate: string
  }

export const SpecificTimeRange = ({
  startDate,
  endDate,
  ...referentialProps
}: PastOrdersProps) => (
  <OrderTable
    queryConfig={{
      queryKey: ["offers", "my-orders"],
      resourceEndpoint: "/api/offers/my-orders",
      queryParams: { startDate, endDate },
    }}
    emptyMessage="Vous n'avez aucune commande passée"
    columns={[
      { renderDefinition: columns.dateAndNumber, width: "15%" },
      { renderDefinition: columns.laboratory, width: "20%" },
      { renderDefinition: columns.offer, width: "55%" },
      { renderDefinition: columns.total, width: "10%" },
    ]}
    {...referentialProps}
  />
)
