import { Yup, YupTypes } from "@pharmupp/p3-front-commons"
import { RATING_MAX } from "../rating.commons"

export const ratingSchema = Yup.object({
  GLOBAL: Yup.object({
    rating: Yup.number().min(1).max(RATING_MAX).required(),
  }),
  COMMERCIAL_CONDITIONS: Yup.object({
    rating: Yup.number().min(1).max(RATING_MAX).required(),
  }),
  PRODUCT_AVAILABILITY: Yup.object({
    rating: Yup.number().min(1).max(RATING_MAX).required(),
  }),
  SUPPORT: Yup.object({
    rating: Yup.number().min(1).max(RATING_MAX).required(),
  }),
  QUALITY: Yup.object({
    rating: Yup.number().min(1).max(RATING_MAX).required(),
  }),
})

export type FormValues = YupTypes.InferType<typeof ratingSchema>
