import { SxProps, Theme } from "@mui/material"

export interface ColumnDef {
  sortable: boolean
  columnName: string
  header: string
  style?: SxProps<Theme>
  /** Style applied only on a row */
  rowStyle?: SxProps<Theme>
}

export const columns: {
  identifier: ColumnDef
  lastName: ColumnDef
  firstName: ColumnDef
  email: ColumnDef
  type: ColumnDef
  status: ColumnDef
  actions: ColumnDef
} = {
  identifier: {
    sortable: false,
    columnName: "functionalId",
    header: "Identifiant",
    style: {
      width: "9%",
    },
  },
  lastName: {
    sortable: true,
    columnName: "lastName",
    header: "Nom",
    style: {
      width: "13%",
    },
    rowStyle: { fontWeight: "bold" },
  },
  firstName: {
    sortable: true,
    columnName: "firstName",
    header: "Prénom",
    style: {
      width: "13%",
    },
    rowStyle: { fontWeight: "bold" },
  },
  email: {
    sortable: false,
    columnName: "email",
    header: "E-mail",
    style: {
      width: "28%",
      overflowX: "hidden",
      textOverflow: "ellipsis",
    },
  },
  type: {
    sortable: false,
    columnName: "type",
    header: "Type",
    style: {
      width: "15%",
    },
  },
  status: {
    sortable: false,
    columnName: "status",
    header: "Statut",
    style: {
      width: "9%",
    },
  },
  actions: {
    sortable: false,
    columnName: "actions",
    header: "",
    style: {
      width: "7%",
    },
  },
}
