import {
  Box,
  Button,
  ButtonOwnProps,
  Stack,
  TableCell,
  Typography,
  styled,
} from "@mui/material"
import {
  ColumnRenderDefinition,
  NumberFormat,
  SortedTableCell,
} from "@pharmupp/p3-front-commons"
import { ListUltraChip, TablePharmacyName } from "../common/components"
import { ApiAdherentLoyaltyList } from "./types"

export const columns = {
  uppAndCity: {
    renderHeader: ({ width }) => <TableCell width={width}>UPP</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Stack>
          <Typography fontSize="inherit" fontWeight="bold">
            {row.uppName}
          </Typography>
          <Typography fontSize="inherit">{row.city}</Typography>
        </Stack>
      </TableCell>
    ),
  },
  pharmacyNameAndHolder: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="pharmacy" width={width}>
        Officine
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        <Stack>
          {!!row.ultra && (
            <Box mb={1}>
              <ListUltraChip />
            </Box>
          )}
          <TablePharmacyName
            name={row.pharmacy}
            negativeSegmentation={row.negativeSegmentation}
          />
          {!!row.primaryHolder && (
            <Typography fontSize="inherit">{`${row.primaryHolder?.firstName} ${row.primaryHolder?.lastName}`}</Typography>
          )}
          <Typography
            fontSize={(theme) => theme.typography.pxToRem(11)}
            color="grey.dark"
          >
            ID : {row.centralBusinessId}
          </Typography>
        </Stack>
      </TableCell>
    ),
  },
  city: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="city" width={width}>
        Ville
      </SortedTableCell>
    ),
    renderRow: ({ row }) => <TableCell>{row.city}</TableCell>,
  },
  separator: {
    renderHeader: ({ width }) => <TableCell align="center" width={width} />,
    renderRow: () => (
      <TableCell align="center" sx={{ position: "relative" }}>
        <Separator />
      </TableCell>
    ),
  },
  categorical: {
    renderHeader: ({ width }) => (
      <TableCell align="center" width={width}>
        Catégoriels
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        <PointsDisplay points={row.categorical} />
      </TableCell>
    ),
  },
  sdav: {
    renderHeader: ({ width }) => (
      <TableCell align="center" width={width}>
        SDAV
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        <PointsDisplay points={row.sdav} />
      </TableCell>
    ),
  },
  qualitative: {
    renderHeader: ({ width }) => (
      <TableCell align="center" width={width}>
        Qualitatif
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        <PointsDisplay points={row.qualitative} />
      </TableCell>
    ),
  },
  total: {
    renderHeader: ({ width }) => (
      <TableCell align="center" width={width}>
        Total cumulé
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        <PointsChip points={row.total} />
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<ApiAdherentLoyaltyList>>

const Separator = styled("div")(({ theme: { palette, spacing } }) => ({
  position: "absolute",
  top: spacing(2),
  bottom: spacing(2),
  width: "1px",
  backgroundColor: palette.grey.extraLight,
}))

const PointsDisplay = ({ points }: { points: number | null }) =>
  !points ? (
    <>-</>
  ) : (
    <Typography
      fontSize={(theme) => theme.typography.pxToRem(12)}
      fontWeight={600}
      color="loyalty.main"
    >
      <NumberFormat value={points} suffix=" pts" />
    </Typography>
  )

export const PointsChip = ({
  points,
  prefix,
  size = "small",
}: {
  points: number | null
  prefix?: string
  size?: ButtonOwnProps["size"]
}) => (
  <Button
    variant="outlined"
    size={size}
    sx={(theme) => ({
      pointerEvents: "none",
      fontWeight: 600,
      ...(points
        ? {
            borderColor: theme.palette.loyalty.main,
            bgcolor: "white",
            color: theme.palette.loyalty.main,
          }
        : {
            borderColor: "#c9d0d6",
            bgcolor: "#eeeff1",
            color: theme.palette.grey.dark,
          }),
    })}
  >
    <NumberFormat value={points} prefix={prefix} suffix=" pts" />
  </Button>
)
