import { Accept, ErrorCode, FileRejection } from "react-dropzone"

export function getLibErrorMessages(fileRejections: FileRejection[]) {
  return [
    ...new Set<ErrorCode>(
      fileRejections.flatMap((rejectedFile) =>
        rejectedFile.errors.map((error) => error.code as ErrorCode),
      ),
    ),
  ].map((error) => errorToLabel?.[error] || error)
}

const errorToLabel: Record<ErrorCode, string> = {
  [ErrorCode.FileInvalidType]: "Type de fichier invalide",
  [ErrorCode.FileTooLarge]: "Fichier trop lourd",
  [ErrorCode.FileTooSmall]: "Fichier trop petit",
  [ErrorCode.TooManyFiles]: "Trop de fichiers",
}

export interface ACCEPTED_FORMAT {
  mime: string
  extensions: string[]
}

export const ACCEPTED_FORMATS = {
  JPG: { mime: "image/jpeg", extensions: [".jpg", ".jpeg"] },
  PNG: { mime: "image/png", extensions: [".png"] },
  GIF: { mime: "image/gif", extensions: [".gif"] },
  WEBP: { mime: "image/webp", extensions: [".webp"] },
  CSV: { mime: "text/csv", extensions: [".csv"] },
} satisfies Record<string, ACCEPTED_FORMAT>

export const getFormatProps = (formats: ACCEPTED_FORMAT[]) =>
  Object.values(formats).reduce(
    (result, { extensions, mime }) => {
      result.acceptedExtensions = [...result.acceptedExtensions, ...extensions]
      result.mimeTypes[mime] = extensions
      return result
    },
    { acceptedExtensions: [], mimeTypes: {} } as {
      acceptedExtensions: string[]
      mimeTypes: Accept
    },
  )
