import { Autocomplete, Typography, debounce, styled } from "@mui/material"
import {
  FormRoleConfContext,
  PaginatedApiResponse,
  query,
  useQuery,
} from "@pharmupp/p3-front-commons"
import { SyntheticEvent, useCallback, useContext, useState } from "react"
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { CreateUserButton } from "./CreateUserButton.component"
import {
  CreateUserModal,
  UserServiceParams,
} from "./CreateUserModal/CreateUserModal.component"
import { CurrentValueField } from "./CurrentValueField.component"
import { NoResultsLayout } from "./NoResultsLayout.component"
import { ApiUser } from "./types"

const startSearchMessage = "Saisir le nom ou prénom pour lancer la recherche"
const noResultsMessage = "Aucun résultat"
const searchErrorMessage = "Une erreur est survenue lors de la recherche"

const filter = (results: ApiUser[] = [], excludedIds: string[] = []) =>
  results.filter((r) => r.id !== null && !excludedIds.includes(r.id))

const getOptionLabel = (option: { lastName: string; firstName: string }) =>
  `${option.lastName} ${option.firstName}`

interface SearchOrCreateUserProps<TFieldValues extends FieldValues = FieldValues> {
  name: FieldPath<TFieldValues>
  label: string
  userServiceParams: UserServiceParams
  excludedUserIds?: string[]
  allowCreate?: boolean
  required?: boolean
}
export function SearchOrCreateUser<TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  excludedUserIds = [],
  userServiceParams,
  allowCreate,
  required = false,
}: SearchOrCreateUserProps<TFieldValues>) {
  const { apiEndpoint } = window.CONTEXT

  const conf = useContext(FormRoleConfContext)
  const roleConfProps = conf[name] || {}
  const canCreate: boolean =
    allowCreate !== undefined
      ? allowCreate
      : (roleConfProps.allowCreate as boolean | undefined) ?? true

  // Internal state
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const onSearchChange = useCallback(debounce(setSearchQuery, 500), [])

  // Getter/setter for the value
  const value = useWatch({ name })
  const { setValue } = useFormContext()
  const onChangeHandler = (
    _event: SyntheticEvent | null,
    newValue: ApiUser | null,
  ) => {
    setValue(name, newValue as TFieldValues[typeof name], {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const {
    data: options,
    isLoading,
    isError: hasSearchFailed,
  } = useQuery({
    queryKey: ["users", "search", searchQuery, excludedUserIds],
    queryFn: () =>
      query<PaginatedApiResponse<ApiUser>>(
        userServiceParams.getSearchUrl(apiEndpoint, searchQuery),
      ),
    select: ({ content: values }): ApiUser[] => {
      // If there's at least one value to show, add the Create User Button at the bottom
      // Otherwise, leave it empty as the Create User button is already shown via noOptionsText
      const filteredValues = filter(values || [], excludedUserIds)
      const valuesWithCurrentValue =
        value?.id && !filteredValues.some((o) => o.id === value.id)
          ? [value, ...filteredValues]
          : filteredValues
      /** @ts-expect-error empty user is a temp value */
      return valuesWithCurrentValue.length
        ? [...valuesWithCurrentValue, { id: null }]
        : []
    },
    enabled: !!searchQuery,
  })
  const loading = isLoading && !!searchQuery

  const getEmptyMessage = () => {
    if (hasSearchFailed) {
      return searchErrorMessage
    }
    if (searchQuery) {
      return noResultsMessage
    }
    return startSearchMessage
  }

  // Modal handlers
  const onClickCreateUserHandler = () => {
    setOpenModal(true)
  }

  const onCloseCreateUserHandler = (createdUser?: ApiUser) => {
    setOpenModal(false)
    if (createdUser) {
      onChangeHandler(null, createdUser)
    }
  }

  return (
    <>
      <Controller
        name={name}
        render={({ field, fieldState: { error } }) => (
          <StyledAutocomplete<ApiUser, false>
            {...field}
            {...roleConfProps}
            id={name}
            open={open}
            disablePortal
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            openText="Ouvrir"
            popupIcon=""
            loading={loading}
            loadingText="Recherche en cours...."
            // Options
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, valueToCheck) =>
              option.id === valueToCheck.id
            }
            noOptionsText={
              <NoResultsLayout
                message={getEmptyMessage()}
                onClick={onClickCreateUserHandler}
                allowCreate={!!canCreate}
              />
            }
            renderOption={(params, option) => {
              if (option.id !== null) {
                return (
                  <Typography {...params} key={option.id}>
                    {getOptionLabel(option)}
                  </Typography>
                )
              }
              return (
                !!canCreate && (
                  <CreateUserButton
                    {...(params as any)}
                    onClick={onClickCreateUserHandler}
                  />
                )
              )
            }}
            getOptionLabel={getOptionLabel}
            options={options || []}
            // Input
            renderInput={(params) => (
              <CurrentValueField
                {...params}
                name={name}
                label={label}
                loading={loading}
                setSearchQuery={onSearchChange}
                error={!!error}
                // @ts-expect-error custom error
                helperText={error?.id?.message || error?.message}
                required={required}
              />
            )}
            value={value?.id ? value : null}
            onChange={onChangeHandler}
            fullWidth
          />
        )}
      />
      <CreateUserModal
        open={openModal}
        onClose={onCloseCreateUserHandler}
        userServiceParams={userServiceParams}
      />
    </>
  )
}

const StyledAutocomplete = styled(Autocomplete)({
  "& + * .MuiAutocomplete-noOptions": {
    paddingLeft: "0",
    paddingTop: "0",
  },
  "&.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root, &.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root":
    {
      paddingRight: "3rem",
    },
}) as typeof Autocomplete
