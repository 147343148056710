import { CheckCircleOutline, ExpandMore } from "@mui/icons-material"
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { ReactNode } from "react"
import { AlertTriangle, XCircle } from "react-feather"

type StatusDisplayProps = {
  status: Status
  title: string
  details?: ReactNode
}
export const StatusDisplay = ({ status, title, details }: StatusDisplayProps) => {
  const { color, Icon } = useStatusStyle(status)

  return (
    // Mandatory div to avoid accordion grouping
    <div>
      <Accordion
        disableGutters={!details}
        sx={{
          borderLeft: `5px solid ${color}`,
          pointerEvents: details ? "auto" : "none",
        }}
      >
        {/* TITLE */}
        <AccordionSummary expandIcon={details ? <ExpandMore /> : null}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Icon style={{ color }} />
            <Typography variant="14px" fontWeight={600}>
              {title}
            </Typography>
          </Stack>
        </AccordionSummary>

        {/* DETAILS */}
        {details && <AccordionDetails>{details}</AccordionDetails>}
      </Accordion>
    </div>
  )
}

type Status = "success" | "warning" | "neutral"

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  svg: {
    width: "22px",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&$expanded": {
    margin: "auto",
  },
}))

const useStatusStyle = (status: Status) => {
  const { palette } = useTheme()
  switch (status) {
    case "success":
      return {
        Icon: CheckCircleOutline,
        color: palette.success.main,
      }
    case "warning":
      return {
        Icon: AlertTriangle,
        color: palette.common.orangeDark2,
      }
    case "neutral":
      return {
        Icon: XCircle,
        color: palette.grey.dark,
      }
  }
}
