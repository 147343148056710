import { useResourceListApi } from "@pharmupp/p3-front-commons"
import { ApiOrder, useOffersOrdersReferentialApi } from "./AdherentOrdersList"
import { OrderRow, getOrdersSelector } from "./AdherentOrdersList/tabs/commons"

export const useMyLabOrdersApi = ({
  labId,
  size = 3,
}: { labId: string; size?: number }) => {
  // REFERENTIAL
  const { referential } = useOffersOrdersReferentialApi()

  // GET
  const {
    list: orders,
    paginationProps: { count },
    initialLoading,
  } = useResourceListApi<ApiOrder, OrderRow>({
    queryKey: ["offers", "orders", "list", labId],
    resourceEndpoint: "/api/offers/my-orders/",
    queryParams: {
      labId,
    },
    rowsPerPage: size,
    select: getOrdersSelector({ referential }),
  })

  return { orders, count, isLoading: initialLoading }
}
