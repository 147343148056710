import Stack from "@mui/material/Stack"
import {
  FieldSection,
  FormDateField,
  FormEmailField,
  FormRoleConfContext,
  FormSwitchField,
  FormTextField,
} from "@pharmupp/p3-front-commons"
import { useFormContext } from "react-hook-form"
import { useUserRoleConf } from "../../../useUserRoleConf"
import { UserFormReferential } from "../../useUserFormApi"
import { ConditionalPhoneField } from "./ConditionalFields/ConditionalPhoneField"
import { ConditionalTypeFields } from "./ConditionalFields/ConditionalTypeFields"
import { GeneralInfosFields, fields } from "./GeneralInfos.fields"
import { formRoleConf, readOnlyConf } from "./GeneralInfos.formRoleConf"

interface GeneralInfosProps {
  fields: GeneralInfosFields
  referential?: UserFormReferential
}

export function GeneralInfos({ referential }: GeneralInfosProps) {
  const { watch } = useFormContext()

  const programmedUnactivate = watch(fields.programmedUnactivate)
  const isUpdate = watch("isUpdate")
  const isReadOnly = watch("isReadOnly")
  const roleConf = useUserRoleConf({
    roleConf: formRoleConf,
    isUpdate,
    readOnlyConf,
    isReadOnly,
  })

  return (
    <FormRoleConfContext.Provider value={roleConf}>
      <FieldSection
        title="Utilisateur"
        subtitle="Saisir les coordonnées de l’utilisateur"
      >
        <Stack direction="row" spacing={2}>
          <FormTextField
            name={fields.lastName}
            inputProps={{ style: { textTransform: "uppercase" } }}
            label="Nom de l'utilisateur"
            fullWidth
            required
          />
          <FormTextField
            name={fields.firstName}
            label="Prénom de l'utilisateur"
            fullWidth
            required
          />
        </Stack>
        <FormEmailField name={fields.email} label="E-mail" required />
        {!!referential && <ConditionalPhoneField referential={referential} />}
      </FieldSection>

      {!!referential && <ConditionalTypeFields referential={referential} />}

      <FieldSection
        title="Désactivation programmée"
        subtitle="Programmer une date de désactivation ?"
      >
        <FormSwitchField
          name={fields.programmedUnactivate}
          toggleLabels={["Non", "Oui"]}
          data-testid="Désactivation programmée"
        />

        {!!programmedUnactivate && (
          <FormDateField
            label="Date de désactivation"
            name={fields.unactivateDate}
          />
        )}
      </FieldSection>
    </FormRoleConfContext.Provider>
  )
}
