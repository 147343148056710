import { ThemeOptions, alpha, createTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { baseTheme } from "./baseTheme"

const { breakpoints, palette, spacing, typography } = baseTheme

/**
 * # Design notes
 *
 * ## Spacing (As decided with Yann):
 * follow MUI 8px based values, pick the closest value to the design
 * eg: design needs 20px, pick 24px / spacing={3}
 */

/**
 * Define components theming using base theme vars
 */
const componentsThemeOptions: ThemeOptions = {
  components: {
    // UI
    MuiAlert: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: "500",
          borderRadius: "100px",
          textTransform: "none",
        },
        sizeMedium: {
          fontSize: "1rem",
          padding: spacing(2),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "header &": {
            color: palette.common.white,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: {
          color: palette.common.white,
        },
        tooltip: {
          backgroundColor: palette.common.white,
          color: palette.primary.main,
          boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
          fontSize: typography.pxToRem(14),
        },
      },
    },

    // Layout

    MuiGrid: {
      styleOverrides: {
        root: {
          /**
           * MUI Grid is broken in v5
           * This fix actual implementations before we could use the new GridV2 component
           * Please use native `display: grid` until GridV2 is released
           */
          margin: 0,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: "6px",
          overflow: "hidden", // Hide content overflow on bg radius
          border: `1px solid ${palette.grey.extraLight}`,
        },
      },
    },

    // Tabs

    MuiTabs: {
      defaultProps: {
        textColor: "primary",
      },
      styleOverrides: {
        flexContainer: {
          gap: "42px",
        },
        indicator: {
          borderRadius: "2px",
          height: "4px",
          left: spacing(2),
          backgroundColor: palette.secondary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          gridRow: "1",
          fontFamily: typography.fontFamily,
          fontSize: "0.875rem",
          fontWeight: 500,
          textTransform: "none",
          flexBasis: "fit-content",
          minWidth: 0,
          padding: 0,
          "&:hover": {
            color: palette.primary.main,
          },
        },
      },
    },

    // Form

    MuiCheckbox: {
      defaultProps: {
        color: "success",
      },
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          "&:not(.Mui-checked)": {
            color: palette.grey.light,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: palette.grey.light,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          backgroundColor: palette.common.white,
          "&.Mui-error": {
            color: palette.grey.dark,
          },
          "&.Mui-disabled": {
            "& fieldset": {
              // !important because fieldset don't have disabled classes, only .MuiOutlinedInput-notchedOutline ( not event .Mui-* )
              // and classes is heavier than component in css
              borderColor: `${alpha(palette.common.black, 0.06)}!important`,
            },
            "&:hover fieldset": {
              borderColor: alpha(palette.common.black, 0.06),
            },
          },
        },
        input: {
          fontSize: "1rem",
          "&::placeholder": {
            fontSize: "1rem",
          },
          "&.Mui-disabled": {
            WebkitTextFillColor: palette.common.darkBlue,
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "success",
      },
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },

    // Table

    MuiTableContainer: {
      styleOverrides: {
        // @ts-ignore
        root: {
          maxHeight: "inherit",
          overflowX: "hidden!important",
          overflowY: "auto!important",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ...typography["12px"],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...typography["12px"],
          border: "none",
          padding: spacing(2, 1),
          [breakpoints.up("md")]: {
            padding: spacing(2),
          },
        },
        head: {
          fontWeight: 400,
          color: palette.grey.light,
          verticalAlign: "bottom",
          background: palette.common.white,
          hyphens: "auto",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          background: palette.common.white,
          "&:nth-of-type(2n+1)": {
            backgroundColor: "#F6F6F7",
          },
          "&.MuiTableRow-hover:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(173, 220, 220, 0.2)!important",
            ".MuiIconButton-root": {
              color: palette.primary.main,
            },
          },
        },
      },
    },
  },
}

export const componentsTheme = createTheme(
  deepmerge(baseTheme, componentsThemeOptions),
)
