import {
  ROLES,
  RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"

export const useAdherentSummaryApi = (args?: { adherentId?: string }) => {
  const getEndpoint = useRoleConf(apiRoleConf)
  const endpoint = getEndpoint(args?.adherentId)
  const { data: summary, isLoading } = useQuery({
    queryKey: ["adherents", args?.adherentId || "my-adherent", "summary"],
    queryFn: () => api.get<AdherentSummary>(endpoint),
  })
  return { summary, isLoading }
}

export interface AdherentSummary {
  id: number
  name: string
  address: {
    line1: string
    line2?: string
    postalCode: string
    city: string
  }
  centralBusinessId: string
  cerpCode: string
  chayallId: null
  cipCode: string
  finessCode: null
  negativeSegmentation: boolean
}

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADHERENT_HOLDER]: () => "/api/adherents/my-adherent/summary",
  [ROLES.ADHERENT_TEAM]: () => "/api/adherents/my-adherent/summary",
  [ROLES.UPP_PRESIDENT]: (id) =>
    id
      ? `/api/adherents/president/${id}/summary`
      : "/api/adherents/my-adherent/summary",
  [ROLES.ADHERENT_ADVISOR]: (id) => `/api/adherents/my-sector/${id}/summary`,
  [ROLES.ADMIN]: (id) => `/api/adherents/${id}/summary`,
}
