import * as qs from "qs"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { isEmpty, omitBy } from "remeda"
import { api, keepPreviousData, useQuery } from "../../../../hooks"
import {
  Config,
  Direction,
  PaginatedApiResponse,
  Response,
  SortProps,
} from "./types"

/**
 * Retrieve a resource list from API
 * Handles pagination, search, sort and filter
 * Opinionated for use with TableList component
 */
export function useResourceListApi<
  QueryResource = unknown,
  Resource = QueryResource,
  Filters extends Record<string, unknown> = {},
>({
  resourceEndpoint,
  queryKey,
  queryParams,
  defaultColumnSort,
  defaultColumnDirection,
  defaultFilter = {},
  rowsPerPage = 12,
  routeStateEnabled = false,
  ...config
}: Config<QueryResource, Resource>): Response<Resource, Filters> {
  const navigate = useNavigate()
  const location = useLocation()

  const routeUrlParams = {
    ...queryParams,
    filters: defaultFilter as Filters,
    search: undefined as unknown as string,
    sortParams: { sort: defaultColumnSort, direction: defaultColumnDirection },
    page: 0,
    ...parseQueryString(location.search),
  }
  const [stateUrlParams, setStateUrlParams] = useState(routeUrlParams)

  const urlParams = routeStateEnabled ? routeUrlParams : stateUrlParams

  const setUrlParams = (
    stateComposer: (existingState: typeof stateUrlParams) => typeof stateUrlParams,
  ) => {
    const newState = stateComposer(urlParams)
    if (routeStateEnabled) {
      navigate({ pathname: location.pathname, search: stringifyQueryObj(newState) })
    } else {
      setStateUrlParams(newState)
    }
  }

  const getParams = stringifyQueryObj({
    ...urlParams,
    ...(urlParams.filters || {}),
    filters: undefined,
    ...(urlParams.sortParams || {}),
    sortParams: undefined,
    size: rowsPerPage,
  })

  const {
    data = {
      list: undefined,
      paginationData: {
        count: 0,
        pageIndex: 0,
        pageNumber: 0,
        pageSize: rowsPerPage,
      },
    },
    isLoading: initialLoading,
    isPlaceholderData: contentLoading,
    refetch: refresh,
  } = useQuery({
    queryKey: [
      ...(queryKey ? queryKey : [resourceEndpoint]),
      "resourceList",
      getParams,
    ],
    queryFn: () =>
      api.get<PaginatedApiResponse<Resource>>(`${resourceEndpoint}?${getParams}`),
    select: ({ content: list, ...pagination }) => {
      const paginationData = {
        count: pagination.totalElements,
        pageIndex: pagination.number,
        pageNumber: pagination.totalPages,
        pageSize: pagination.size,
      }
      if ("select" in config && config.select) {
        return {
          // @ts-expect-error need TypeScript master
          list: config.select(list || []),
          paginationData,
        }
      }
      return { list, paginationData }
    },
    // Keeps previous loaded data in cache when loading new one (= prev pages)
    placeholderData: keepPreviousData,
  })

  const loadPage = (pageIndexToLoad = 0) => {
    setUrlParams((previous) => ({ ...previous, page: pageIndexToLoad }))
  }

  const search = (newSearchValue: string) => {
    setUrlParams((previous) => ({ ...previous, search: newSearchValue, page: 0 }))
  }

  const _sort = (column: string, direction: Direction) => {
    const sortParams = {
      sort: column,
      direction: (direction?.toLocaleUpperCase() || "ASC") as Direction,
    }
    setUrlParams((previous) => ({ ...previous, sortParams: sortParams }))
  }
  const [sortColumn, setSortColumn] = useState(
    defaultColumnSort ?? urlParams.sortParams?.sort ?? "",
  )
  const [sortDirection, setSortDirection] = useState<Direction>(
    defaultColumnDirection ?? urlParams.sortParams?.direction ?? "desc",
  )
  const sortBy = (columnName: string, direction?: "asc" | "desc") => {
    const _direction = direction
      ? direction
      : sortDirection === "asc"
        ? "desc"
        : "asc"
    setSortColumn(columnName)
    setSortDirection(_direction)
    _sort(columnName, _direction)
  }
  const sortProps: SortProps = {
    currentSortName: sortColumn,
    currentSortDirection: sortDirection,
    onClick: sortBy,
  }

  const filter = (filterValues: Filters) => {
    setUrlParams((previous) => ({ ...previous, filters: filterValues, page: 0 }))
  }

  return {
    // Loaders
    initialLoading,
    contentLoading,
    // Values
    list: data.list === null ? [] : data.list || undefined,
    paginationProps: {
      rowsPerPage: data.paginationData.pageSize,
      page: data.paginationData.pageIndex,
      count: data.paginationData.count,
      onPageChange: loadPage,
    },
    sortProps,
    // Actions
    refresh,
    loadPage,
    search,
    currentSearch: urlParams.search,
    filter,
    currentFilters: urlParams.filters,
    isFiltered: !!urlParams.search || !isEmpty(urlParams.filters),
  }
}

const stringifyQueryObj = (obj: Record<string, unknown>) =>
  // Remove empty strings to avoid empty query params
  qs.stringify(
    omitBy(obj, (el) => el === ""),
    { arrayFormat: "repeat" },
  )

export const parseQueryString = (queryString: string) =>
  qs.parse(queryString, {
    ignoreQueryPrefix: true,
    decoder(str, decoder, charset) {
      const strWithoutPlus = str.replace(/\+/g, " ")
      if (charset === "iso-8859-1") {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return Number.parseFloat(str)
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      }
      if (str in keywords) {
        // @ts-expect-error the key exists
        return keywords[str]
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus)
      } catch (e) {
        return strWithoutPlus
      }
    },
  })
