import { ERROR_KINDS } from "@pharmupp/p3-front-commons"
import { fields } from "./UserForm/tabs/GeneralInfos/GeneralInfos.fields"
import {
  ApiErrorHandlerConfig,
  HandledError,
  UnhandledError,
} from "./useApiErrorHandler"

const saveErrorHandler = (error: UnhandledError) => {
  let fieldInError
  let errorMessage
  let handledErrorType: "toaster" | "fieldError" | "dialogAction" = "toaster"
  let handledError: HandledError = {
    type: "toaster",
    toasterMessage: "Une erreur est survenue",
  }

  if (error.statusCode === 409 && error.kind === ERROR_KINDS.ALREADY_EXIST) {
    handledErrorType = "fieldError"
    fieldInError = fields.email
    errorMessage = "Cette adresse mail est déjà utilisée par un autre utilisateur"
  } else if (error.statusCode === 417) {
    if (error.kind === ERROR_KINDS.NOT_MOVABLE_COMMITTEE) {
      handledErrorType = "fieldError"
      fieldInError = fields.type
      errorMessage = `Vous ne pouvez pas modifier le type d'utilisateur car il est membre du comité de direction de l'UPP ${error.reason}`
    } else if (error.kind === ERROR_KINDS.NOT_MOVABLE_ADHERENT) {
      handledErrorType = "fieldError"
      fieldInError = fields.type
      errorMessage = `Vous ne pouvez pas modifier le type d'utilisateur car il est titulaire de la pharmacie ${error.reason}`
    } else if (error.kind === ERROR_KINDS.NOT_ACTIVATABLE) {
      errorMessage =
        "Vous ne pouvez pas activer cet utilisateur car il n’est rattaché à aucune pharmacie"
      handledError = {
        key: ERROR_KINDS.NOT_ACTIVATABLE,
        type: "toaster",
        toasterMessage: errorMessage,
      }
    }
  } else if (error.statusCode === 412) {
    if (error.kind === ERROR_KINDS.ALREADY_EXIST) {
      handledErrorType = "dialogAction"
      handledError = {
        key: ERROR_KINDS.ALREADY_EXIST,
        type: "dialogAction",
        props: {
          validateButtonText: "Oui",
          successMessage:
            "L'utilisateur a bien été activé en tant que membre de l'officine",
          // @ts-ignore
          url: "",
          method: "",
          body: "",
          title: "Email déjà utilisé",
          message:
            "Cet utilisateur existe déjà dans votre officine. Souhaitez-vous l’activer en tant que membre de officine ?",
        },
      }
    }
  }

  if (handledErrorType === "fieldError" && fieldInError && errorMessage) {
    handledError = {
      type: "fieldError",
      props: {
        fieldName: fieldInError,
        message: errorMessage,
      },
      toasterMessage: "Veuillez remplir correctement le formulaire",
    }
  }

  return handledError
}

/**
 * @param {string} error
 */
const deleteErrorHandler = (error: UnhandledError) => {
  let errorMessage
  if (error.statusCode === 417) {
    if (error.kind === ERROR_KINDS.NOT_MOVABLE_COMMITTEE) {
      errorMessage = `Vous ne pouvez pas supprimer cet utilisateur car il est membre du comité de direction de l'UPP ${error.reason}`
    } else if (error.kind === ERROR_KINDS.NOT_MOVABLE_ADHERENT) {
      errorMessage = `Vous ne pouvez pas supprimer cet utilisateur car il est titulaire de la pharmacie ${error.reason}`
    }
  }

  const handledError: HandledError = errorMessage
    ? {
        type: "dialogInfo",
        props: {
          title: "Suppression",
          message: errorMessage,
        },
      }
    : {
        type: "toaster",
        toasterMessage: "Une erreur est survenue",
      }

  return handledError
}

export function isHandledError(error: unknown): error is UnhandledError {
  return (
    !!error && typeof error === "object" && "statusCode" in error && "kind" in error
  )
}

export const apiErrorHandlerConfig: ApiErrorHandlerConfig = {
  saveErrorHandler,
  deleteErrorHandler,
}
