import { api, useQuery } from "./http"

export const useUppsList = ({ enabled }: { enabled: boolean }) => {
  const { data: uppsList } = useQuery({
    queryKey: ["admin", "upps", "dataref"],
    queryFn: () => api.get<UppsDatarefApi[]>("/api/upps/dataref"),
    select: (rawData) => rawData.map((upp) => ({ code: upp.id, label: upp.name })),
    enabled,
  })
  return { uppsList }
}

interface UppsDatarefApi {
  id: number
  name: string
}
