import {
  Button,
  CircularProgress,
  DialogProps,
  Divider,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  styled,
  useTheme,
} from "@mui/material"
import { ReactNode } from "react"
import type { Icon as FeatherIcon } from "react-feather"

export interface CommonsDialogProps
  extends Omit<DialogProps, "title">,
    DialogBasicActionsProps {
  Icon?: FeatherIcon
  title: ReactNode
  open: boolean
  loading?: boolean
  children?: ReactNode
  width?: string
}

/**
 * Dialogs inform users about a task and can contain critical information, require decisions, or involve multiple tasks.
 */
export const Dialog = ({
  Icon,
  title,
  cancelText,
  cancelAction,
  validateText,
  validateAction,
  validateEnabled = true,
  width,
  children,
  ...dialogProps
}: CommonsDialogProps) => {
  const { palette, spacing } = useTheme()

  return (
    <DialogContainer
      sx={{ width: width ?? "720px", alignItems: "center", gap: spacing(2) }}
      {...dialogProps}
    >
      {!!Icon && <Icon size="61px" color={palette.primary.light1} />}

      <DialogTitle>{title}</DialogTitle>

      <Divider sx={{ width: "30%" }} />

      <DialogContent sx={{ minWidth: "350px", my: 1, mx: "auto" }}>
        {typeof children === "string" ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>

      <DialogBasicActions
        cancelText={cancelText}
        cancelAction={cancelAction}
        validateText={validateText}
        validateAction={validateAction}
        validateEnabled={validateEnabled}
      />
    </DialogContainer>
  )
}

type DialogContainerProps = MuiDialogProps & {
  loading?: boolean
}

const DialogContainer = ({
  open,
  loading = false,
  sx,
  children,
  ...props
}: DialogContainerProps) => (
  <MuiDialog
    {...props}
    open={open}
    PaperProps={{ elevation: 1, sx: { ...sx, py: 6, px: 4 } }}
  >
    {loading ? <CircularProgress /> : children}
  </MuiDialog>
)

const DialogTitle = styled(MuiDialogTitle)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(30),
  fontWeight: 600,
  color: palette.grey.dark,
  textAlign: "center",
}))

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  color: theme.palette.grey.dark,
}))

const DialogContentText = styled(MuiDialogContentText)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(20),
    fontWeight: "normal",
    color: palette.grey.dark,
    textAlign: "center",
  }),
)

const DialogActions = styled(MuiDialogActions)(() => ({
  justifyContent: "center",
}))

interface DialogBasicActionsProps {
  cancelAction?: () => void
  cancelText?: string
  validateAction: () => void
  validateText?: string
  validateEnabled?: boolean
}
const DialogBasicActions = ({
  cancelAction,
  cancelText = "Annuler",
  validateAction,
  validateText = "Valider",
  validateEnabled = true,
}: DialogBasicActionsProps) => (
  <DialogActions>
    {!!cancelAction && <Button onClick={cancelAction}>{cancelText}</Button>}
    <Button onClick={validateAction} variant="contained" disabled={!validateEnabled}>
      {validateText}
    </Button>
  </DialogActions>
)
