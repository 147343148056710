import { ErrorOutlineOutlined } from "@mui/icons-material"
import { BoxProps } from "@mui/material"
import {
  DashboardInfoBanner,
  DashboardInfoBannerCaption,
  DashboardInfoBannerLink,
  DashboardInfoBannerProps,
  DashboardInfoBannerTitle,
  useDialog,
} from "@pharmupp/p3-front-commons"
import { HelpCircle } from "react-feather"
import { NegativeModal } from "./NegativeModal"

export const MyNegativeSegmentationBanner = (
  props: BoxProps & Pick<DashboardInfoBannerProps, "size" | "iconSize">,
) => {
  const dialog = useDialog()

  return (
    <DashboardInfoBanner {...props} variant="negativeSegmentation">
      <ErrorOutlineOutlined />
      <div>
        <DashboardInfoBannerTitle color="negativeSegmentation.main">
          Votre pharmacie est en segmentation négative
        </DashboardInfoBannerTitle>
        &nbsp;
        <DashboardInfoBannerCaption>
          :&nbsp;le versement des primes Vie des UPP et des points Challenges est
          donc suspendu.
        </DashboardInfoBannerCaption>
        &nbsp;
        <DashboardInfoBannerLink
          onClick={() =>
            dialog.info({
              message: <NegativeModal />,
              title: "Pharmacie en segmentation négative",
              validateText: "J'ai compris",
              width: "75vw",
              Icon: HelpCircle,
            })
          }
        >
          En savoir +
        </DashboardInfoBannerLink>
      </div>
    </DashboardInfoBanner>
  )
}
