import {
  Box,
  Rating as MuiRating,
  RatingProps as MuiRatingProps,
  ratingClasses,
  styled,
} from "@mui/material"
import { ComponentType } from "react"

export type RatingProps = Omit<MuiRatingProps, "size"> & {
  size?: MuiRatingProps["size"] | "extraLarge"
  renderRatingLabel?: (value?: number | null) => React.ReactNode
}

export const Rating = ({ value, renderRatingLabel, ...props }: RatingProps) => (
  <Box display="flex" alignItems="center" gap={2}>
    <StyledRating
      value={value != null ? Math.floor(value) : value}
      readOnly
      {...props}
    />
    {renderRatingLabel?.(value)}
  </Box>
)

const StyledRating = styled(MuiRating as ComponentType<RatingProps>)(
  ({ theme, size }) => ({
    ...(size === "extraLarge" && {
      [`& .${ratingClasses.icon}`]: {
        fontSize: theme.typography.pxToRem(34),
      },
    }),
  }),
)
