import { LoadingButton } from "@mui/lab"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  DialogProps as MuiDialogProps,
  Stack,
  styled,
} from "@mui/material"
import { PropsWithChildren, useState } from "react"
import { X } from "react-feather"
import { PaperContainerTitle } from "../../layout/PaperContainer"

export type ModalProps = Omit<MuiDialogProps, "onClose"> & {
  onClose?: () => void
  loading?: boolean
  showCross?: boolean
}

/**
 * Modals allows more customization on content than Dialogs (lower level than dialogs)
 */
export const Modal = ({
  open,
  onClose,
  loading = false,
  showCross = false,
  sx,
  children,
  ...props
}: ModalProps) => (
  <Dialog
    {...props}
    open={open}
    onClose={onClose}
    PaperProps={{ elevation: 1, sx: { ...sx, p: 5, pb: 4 } }}
  >
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        {showCross && onClose && (
          <ModalCrossButton onClick={() => onClose()}>
            <X />
          </ModalCrossButton>
        )}
        {children}
      </>
    )}
  </Dialog>
)

export const ModalTitle = PaperContainerTitle

export const ModalContent = styled(DialogContent)(({ theme: { spacing } }) => ({
  padding: spacing(4, 0),
}))

export const ModalContentText = styled(DialogContentText)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(20),
    lineHeight: typography.pxToRem(22),
    fontWeight: "normal",
    color: palette.grey.dark,
  }),
)

export const ModalFooter = ({ children }: PropsWithChildren) => (
  <Stack spacing={4}>
    <ModalDivider />
    {children}
  </Stack>
)

export const ModalDialogActions = styled(DialogActions)(() => ({}))

export interface ModalBasicActionsProps {
  cancelAction?: () => void
  cancelText?: string
  validateAction: () => Promise<void> | void
  validateText?: string
  validateEnabled?: boolean
}
export const ModalBasicActions = ({
  cancelAction,
  cancelText = "Annuler",
  validateAction,
  validateText = "Valider",
  validateEnabled = true,
}: ModalBasicActionsProps) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <ModalFooter>
      <ModalDialogActions>
        {!!cancelAction && <Button onClick={cancelAction}>{cancelText}</Button>}
        <LoadingButton
          onClick={async () => {
            setIsLoading(true)
            try {
              await validateAction()
            } finally {
              setIsLoading(false)
            }
          }}
          variant="contained"
          disabled={!validateEnabled}
          loading={isLoading}
        >
          {validateText}
        </LoadingButton>
      </ModalDialogActions>
    </ModalFooter>
  )
}

export const ModalDivider = styled(Divider)({
  borderColor: "#f0f1f2",
})

const ModalCrossButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
}))
