import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Card, Divider, Stack, Typography } from "@mui/material"
import {
  FormRatingField,
  Modal,
  ModalBasicActions,
  ModalContent,
  ModalDivider,
  ModalProps,
  ModalTitle,
} from "@pharmupp/p3-front-commons"
import { useEffect } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { RATING_CATEGORY, RATING_MAX } from "../rating.commons"
import { useRatingSummaryApi } from "../useRatingSummaryApi"
import {
  CategoryTitle,
  GlobalRating,
  GlobalRatingLine,
  GlobalRatingValue,
  ScoreLine,
  getScoreLabel,
} from "./commons"
import { FormValues, ratingSchema } from "./rating.schema"
import { useRatingApi } from "./useRatingApi"

type UpdateRatingModalProps = Omit<ModalProps, "open" | "onClose"> & {
  labId: string
  onClose: () => void
}

export const UpdateRatingModal = ({ onClose, labId }: UpdateRatingModalProps) => {
  // API
  const { ratingSummary } = useRatingSummaryApi({ labId })
  const { rating, updateRating } = useRatingApi({ labId })

  // FORM
  const formApi = useForm<FormValues>({
    resolver: yupResolver(ratingSchema),
  })

  const userGlobalRating = useWatch<FormValues, "GLOBAL.rating">({
    name: "GLOBAL.rating",
    control: formApi.control,
  })

  useEffect(() => {
    if (rating) {
      formApi.reset(rating)
    }
  }, [formApi, rating])

  return (
    <Modal open onClose={onClose} showCross sx={{ minWidth: "720px" }}>
      <ModalTitle>Avis des Adhérents</ModalTitle>

      <ModalContent>
        <Stack spacing={5.5} divider={<Divider />}>
          {/* GLOBAL SCORE */}
          <GlobalRating
            averageValue={ratingSummary.GLOBAL.averageRating}
            ratingsCount={ratingSummary.GLOBAL.ratingsCount}
          />
        </Stack>
      </ModalContent>

      <ModalDivider sx={{ mb: 4 }} />

      <ModalTitle>Votre avis</ModalTitle>

      <ModalContent>
        <form>
          <FormProvider {...formApi}>
            <Stack gap={2}>
              <GlobalRatingLine>
                <GlobalRatingValue value={userGlobalRating} />
                <FormRatingField<FormValues>
                  name="GLOBAL.rating"
                  max={RATING_MAX}
                  size="extraLarge"
                />
              </GlobalRatingLine>

              <Typography color="grey.dark">
                Notez votre expérience avec le laboratoire d’après les critères
                suivants
              </Typography>

              <Stack gap={1}>
                <CategoryScore name="QUALITY" label="Qualité" />
                <CategoryScore
                  name="COMMERCIAL_CONDITIONS"
                  label="Conditions commerciales"
                />
                <CategoryScore
                  name="PRODUCT_AVAILABILITY"
                  label="Disponibilités produits"
                />
                <CategoryScore name="SUPPORT" label="Accompagnement" />
              </Stack>
            </Stack>
          </FormProvider>
        </form>
      </ModalContent>

      <ModalBasicActions
        validateAction={formApi.handleSubmit(
          async function onSuccess(values) {
            await updateRating(values)
            onClose()
          },
          function onError(errors) {
            console.error(errors)
          },
        )}
        validateText="Enregistrer"
      />
    </Modal>
  )
}

const CategoryScore = ({
  label,
  name,
}: { label: string; name: RATING_CATEGORY }) => (
  <Card component={Box} px={2.5} py={1}>
    <ScoreLine>
      <CategoryTitle>{label}</CategoryTitle>
      <FormRatingField<FormValues>
        name={`${name}.rating`}
        max={RATING_MAX}
        size="large"
        renderRatingLabel={(value) => (
          <Typography variant="14px" fontWeight={500} color="primary.dark1">
            {getScoreLabel(value)}
          </Typography>
        )}
      />
    </ScoreLine>
  </Card>
)
