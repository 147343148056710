import { useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Yup } from "../../yup-custom"
import { FormTab } from "./TabbedForm"

/**
 * Merge each tab schema into a big one
 */
const mergeTabsSchema = (tabs: FormTab[]) =>
  tabs.reduce((result, tab) => result.concat(tab.schema), Yup.object())

/**
 * Creates a dictionary field -> route for validation
 */
const getFieldRouteMap = (tabs: FormTab[]) =>
  tabs.reduce((result, tab) => {
    for (const field of Object.keys(tab.schema.fields)) {
      result.set(field, tab.to)
    }
    return result
  }, new Map<string, string>())

const isCurrentTabValid = (currentPath: string, erroredTabs: string[]) =>
  !erroredTabs.some((tab) => currentPath.includes(tab))

export const useTabsValidation = ({ tabs }: { tabs: FormTab[] }) => {
  // Routing
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const schema = useMemo(() => mergeTabsSchema(tabs), [tabs])
  const fieldRouteMap = useMemo(() => getFieldRouteMap(tabs), [tabs])
  const [invalidTabs, setInvalidTabs] = useState<string[]>([])
  const currentTabs = useMemo(
    () =>
      tabs.map((tab) => ({
        ...tab,
        valid: !invalidTabs.includes(tab.to),
        label: tab.label,
      })),
    [invalidTabs, tabs],
  )

  const validateTabs = useCallback(
    (
      /** Error keys are fields names */
      errors: Record<string, unknown> = {},
    ) => {
      const sortedFieldsByTab = Array.from(fieldRouteMap.keys())
      const currInvalidTabs = Array.from(
        new Set(
          Object.keys(errors)
            // Sort by tab order to have ordered error tabs
            .sort(
              (a, b) => sortedFieldsByTab.indexOf(a) - sortedFieldsByTab.indexOf(b),
            )
            .map((field) => fieldRouteMap.get(field)!),
        ),
      )
      setInvalidTabs(currInvalidTabs)
      // Stay on the current tab if invalid, otherwise navigate to the first invalid one
      if (
        isCurrentTabValid(pathname, currInvalidTabs) &&
        currInvalidTabs.length > 0
      ) {
        navigate(currInvalidTabs[0])
      }
    },
    [fieldRouteMap, navigate, pathname],
  )

  const resetInvalidTabs = () => {
    setInvalidTabs([])
  }

  return {
    currentTabs,
    /** All tabs schema merged into one */
    schema,
    validateTabs,
    resetInvalidTabs,
  }
}
