import { ModalProps } from "@pharmupp/p3-front-commons"
import { Route, Routes } from "react-router-dom"
import { UpdateRatingModal } from "./UpdateRatingModal"
import { ViewRatingModal } from "./ViewRatingModal"

type RatingModalProps = Omit<ModalProps, "open" | "onClose"> & {
  labId: string
  onClose: () => void
}
export const RatingModal = ({ onClose, labId }: RatingModalProps) => (
  <Routes>
    <Route
      path="notes"
      element={<ViewRatingModal onClose={onClose} labId={labId} />}
    />
    <Route
      path="noter"
      element={<UpdateRatingModal onClose={onClose} labId={labId} />}
    />
  </Routes>
)
