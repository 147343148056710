import {
  Direction,
  api,
  useMutation,
  useQueryClient,
  useResourceListApi,
} from "@pharmupp/p3-front-commons"

export const getAppointmentApiEndpoint = (id: string | number) =>
  `/api/laboratories/adherent/${id}/appointments`

export const getAppointmentQueryKey = (id: string | number) => [
  ["laboratories", id, "adherent", "appointments"],
]

export const useAppointmentApi = ({
  labId,
  sortDateDirection,
  size,
}: {
  labId: string
  sortDateDirection: Direction
  size: number
}) => {
  const endpoint = getAppointmentApiEndpoint(labId)
  const queryKey = getAppointmentQueryKey(labId)

  const queryClient = useQueryClient()

  // GET
  const { list } = useResourceListApi<ApiLabAppointment>({
    queryKey,
    resourceEndpoint: endpoint,
    defaultColumnSort: "dateTime",
    defaultColumnDirection: sortDateDirection,
    rowsPerPage: size,
  })
  // UPDATE
  const { mutate: createAppointment } = useMutation({
    mutationFn: (newAppointment: ApiNewLabAppointment) =>
      api.post<ApiLabAppointment>(endpoint, {
        body: JSON.stringify(newAppointment),
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  const refreshAppointment = () => queryClient.invalidateQueries({ queryKey })

  return { appointments: list, createAppointment, refreshAppointment }
}

export type ApiLabAppointment = {
  id: number
  title: string
  types: AppointmentType[]
  dateTime: string // DateTime ISO without TZ (LocalDateTime)
  hasReport: boolean
}
type ApiNewLabAppointment = Omit<ApiLabAppointment, "id">

export type AppointmentType = "PROSPECTIVE" | "ANNUAL"
