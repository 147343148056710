import { AlertCircle } from "react-feather"
import type { Icon as FeatherIcon } from "react-feather"
import { Dialog } from "../Dialog"

export interface InfoDialogProps {
  open: boolean
  title: string
  message: string | React.JSX.Element
  onClickClose: () => void
  validateText?: string
  width?: string
  Icon?: FeatherIcon
}

export const InfoDialog = ({
  open,
  title,
  message,
  onClickClose,
  validateText,
  width,
  Icon,
}: InfoDialogProps) => (
  <Dialog
    Icon={Icon ?? AlertCircle}
    title={title}
    validateAction={onClickClose}
    validateText={validateText ?? "OK"}
    open={open}
    loading={false}
    onClose={onClickClose}
    width={width}
  >
    {message}
  </Dialog>
)
